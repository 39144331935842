<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Proyek</label>
        <vs-input class="w-full" :value="initData.header.kode_proyek" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">No.Unit</label>
        <vs-input class="w-full" :value="initData.header.no_unit" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">No.Izin</label>
        <vs-input class="w-full" :value="initData.header.no_ijin" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Tgl.Mulai</label>
        <vs-input class="w-full" :value="initData.header.tgl_mulai" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Tgl Akhir</label>
        <vs-input class="w-full" :value="initData.header.tgl_akhir" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Telp</label>
        <vs-input class="w-full" :value="initData.header.telp" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Atas Nama</label>
        <vs-input class="w-full" :value="initData.header.atas_nama" readonly/>
      </div>
      <div class="vx-col sm:w-8/12 w-full">
        <label class="ml-1 text-xs">Jenis Perijinan</label>
        <vs-input class="w-full" :value="initData.header.nama_item_perijinan_warga" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/1 w-full">
        <label class="ml-1 text-xs">Keterangan</label>
        <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" :value="initData.header.keterangan" readonly/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TabHeader',
  components: {},
  props: {
    isActive: { required: true, type: Boolean },
    initData: { default: null, type: Object }
  },
  methods: {}
}
</script>
